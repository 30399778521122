<template>
  <v-app>
    <v-main>
      <v-container class="main-container container--fluid">
        <v-card elevation="0" style="border-radius: 0px;border:1px solid lightgrey" class="buttons-card">
          <v-app-bar dark color="green">
            <v-tabs grow v-model="tab">
              <v-tab v-for="tab in controls"
                     :key="tab.tab.caption">
                {{ tab.tab.caption }}
              </v-tab>
            </v-tabs>
          </v-app-bar>
          <v-tabs-items v-model="tab">
            <v-tab-item
                style="overflow-y: hidden; overflow-x: hidden; padding: 1rem"
                v-for="(tabItem) in controls"
                :key="tabItem.tab.caption"
            >
              <v-row>
                <v-col>
                  <v-row style="display: flex; align-items: center" class="left-buttons-block">
                    <v-col>
                      <v-btn-toggle
                          v-model="tabItem.selectedButton"
                      >
                        <v-btn small block rounded
                               :color="tabItem.tab.color" v-for="btn in tabItem.btns"
                               :key="btn.method">
                          {{ btn.caption }}
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col style="display:flex; flex-direction: column">
                  <template v-if="tab == undefined || controls[tab].selectedButton == undefined">
                    <div>
                      <h4>Select action to add on a right hand side</h4>
                    </div>
                  </template>
                  <template v-else>
                    <v-row v-if="typeof selectedButton.sender == 'string'" style="flex-grow: 0">
                      <v-col>
                        <span>Execute by: {{ selectedButton.sender }}</span>
                      </v-col>
                    </v-row>
                    <v-row v-else style="flex-grow: 0">
                      <v-col>
                        <v-select dense v-model="selectedButton.currentModel.sender"
                                  hide-details :items="senderItems"
                                  label="Execute by"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row v-for="(param, i) in selectedButton.params" :key="i" style="flex-grow: 0">
                      <v-col>
                        <v-select dense v-model="selectedButton.currentModel.params[i]"
                                  hide-details :items="userItems"
                                  :label="param.caption"
                                  v-if="param.type == USERID"
                        >
                        </v-select>
                        <v-select dense v-model="selectedButton.currentModel.params[i]"
                                  hide-details :items="poolItems"
                                  :label="param.caption"
                                  v-if="param.type == POOLID"
                        >
                        </v-select>
                        <v-select dense v-model="selectedButton.currentModel.params[i]"
                                  hide-details :items="poolItems"
                                  :label="param.caption"
                                  multiple
                                  v-if="param.type == POOLLISTID"
                        >
                        </v-select>
                        <v-select dense v-model="selectedButton.currentModel.params[i]"
                                  hide-details :items="riskFactoritem"
                                  :label="param.caption"
                                  v-if="param.type == RISKFACTORID"
                        >
                        </v-select>
                        <v-select dense v-model="selectedButton.currentModel.params[i]"
                                  hide-details :items="claimItems"
                                  :label="param.caption"
                                  v-if="param.type == CLAIMID"
                        >
                        </v-select>
                        <v-text-field v-model="selectedButton.currentModel.params[i]"
                                      hide-details dense type="number"
                                      :label="param.caption"
                                      v-if="param.type == MONEY"
                        ></v-text-field>
                        <v-text-field v-model="selectedButton.currentModel.params[i]"
                                      hide-details dense type="number"
                                      :label="param.caption"
                                      v-if="param.type == PERCENT"
                        ></v-text-field>
                        <v-text-field v-model="selectedButton.currentModel.params[i]"
                                      hide-details dense type="number"
                                      :label="param.caption"
                                      v-if="param.type == NUMBER"
                        ></v-text-field>
                        <v-text-field v-model="selectedButton.currentModel.params[i]"
                                      hide-details dense
                                      :label="param.caption"
                                      v-if="param.type == STRING"
                        ></v-text-field>
                        <v-checkbox
                            v-model="selectedButton.currentModel.params[i]"
                            dense
                            :label="param.caption"
                            v-if="param.type == BOOL"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-spacer style="flex-grow: 1"></v-spacer>
                    <br/>
                    <v-btn @click="scenarioAdd" large block rounded color="primary" style="flex-grow: 0">Add to Scenario
                    </v-btn>
                  </template>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
        <v-card elevation="0" style="border-radius: 0px;border:1px solid lightgrey" class="log-card">
          <v-app-bar dark color="indigo darken-2">
            <v-toolbar-title>Results</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn @click="saveScenarioLog" small rounded :disabled="!processLog.length">Save Log</v-btn>
            <v-btn @click="runEmulation" small rounded :disabled="!scenario.length"
                   :loading="executing">
              Run emulation
            </v-btn>
          </v-app-bar>
          <div class="log-container">
            <div v-for="(log, index) in processLog" :key="index">
              <span v-html="log"/>
            </div>
          </div>
          <div style="font-size:0.8rem;background-color:#e0e0e0"> Error count: {{ countError }}</div>
        </v-card>
        <v-card elevation="0" style="border-radius: 0px;border:1px solid lightgrey" class="scenario-card">
          <v-app-bar dark color="purple">
            <v-toolbar-title>Scenario</v-toolbar-title>
            <v-spacer></v-spacer>
            <input type="file" accept=".json" ref='filePickerJson'
                   @change="handleImportFileJson( $event )"
                   style="visibility: none; width:0px; height:0px"/>
            <v-btn small rounded @click="importJson" :disabled="importExportProcessStarted">Import Json</v-btn>

            <input type="file" accept=".xls, .xlsx" ref='filePickerXls'
                   @change="handleImportFileXls( $event )"
                   style="visibility: none; width:0px; height:0px"/>
            <v-btn small rounded @click="importXls" :disabled="importExportProcessStarted">Import Xls</v-btn>
            <v-btn small rounded @click="exportXls" :disabled="importExportProcessStarted">Export Xls</v-btn>
            <v-btn small rounded @click="copyToBuffer" :disabled="importExportProcessStarted">Copy to buffer</v-btn>
            <v-btn small rounded @click="reset">Reset</v-btn>
          </v-app-bar>
          <v-container style="overflow-y: scroll">
            <v-row>
              <v-col cols="12">
                <v-list>
                  <v-list-item dense v-for="(item, index) in scenario" :key="index">
                    <v-list-item-content>
                      <v-list-item-title
                          v-html="formatScenarioItem(item)">
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-btn :disabled="index == 0" @click="moveUp(index)" icon
                             color="purple">
                        <v-icon>mdi-arrow-up</v-icon>
                      </v-btn>
                      <v-btn :disabled="index == scenario.length-1"
                             @click="moveDown(index)" icon color="purple">
                        <v-icon>mdi-arrow-down</v-icon>
                      </v-btn>
                      <v-btn @click="cloneData(index)" icon color="purple">
                        <v-icon>mdi-content-copy</v-icon>
                      </v-btn>
                      <v-btn @click="deleteData(index)" icon color="purple">
                        <v-icon>mdi-delete-outline</v-icon>
                      </v-btn>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-container>
        </v-card>

      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import {Users, Senders, Pools, Claims, Controls, Risks} from './Settings'
import {
  USERID,
  POOLID,
  POOLLISTID,
  CLAIMID,
  RISKFACTORID,
  NUMBER,
  PERCENT,
  BOOL,
  MONEY,
  STRING
} from './Settings'
import axios from 'axios'
const moment = require('moment')
const ExcelJS = require('exceljs')
import {saveAs} from 'file-saver'

export default {
  name: 'App',
  components: {},
  data: () => ({
    tab: null,
    controls: Controls.map((item) => {
      item.btns.map((subitem) => {
        subitem.currentModel = {
          sender: subitem.sender,
          params: []
        }
        subitem.params = []
        for (let i = 1; i < 10; i++) {
          if (subitem['param' + i]) {
            subitem.params.push(subitem['param' + i])
            subitem.currentModel.params[i - 1] = subitem['param' + i].value
          }
        }
        return subitem
      })
      return item
    }),

    jsonFile: null,
    xlsFile: null,
    btn_loading: false,
    USERID: USERID,
    POOLID: POOLID,
    POOLLISTID: POOLLISTID,
    CLAIMID: CLAIMID,
    RISKFACTORID: RISKFACTORID,
    NUMBER: NUMBER,
    PERCENT: PERCENT,
    BOOL: BOOL,
    MONEY: MONEY,
    STRING: STRING,
    processLog: [],
    scenario: [],
    countError: 0,
    importExportProcessStarted: false,

    userItems: Users,
    senderItems: Senders,
    poolItems: Pools,
    claimItems: Claims,
    riskFactoritem: Risks,

    executing: false,
    port: process.env.VUE_APP_BACKEND_PORT,
    server_address: process.env.VUE_APP_BACKEND_ADDRESS,
    protocol: process.env.VUE_APP_BACKEND_PROTOCOL,
  }),
  computed: {
    selectedButton() {

      return this.controls[this.tab].btns[this.controls[this.tab].selectedButton]
    }
  },
  methods: {
    reset() {
      this.scenario = []
      this.processLog = []
    },
    formatScenarioItem(item) {
      return JSON.stringify(item)
    },
    async copyToBuffer() {
      try {
        await navigator.clipboard.writeText(JSON.stringify(this.scenario, null, 4));
      } catch ($e) {
        alert('Cannot copy');
      }
    },
    arrayMove(arr, fromIndex, toIndex) {
      var element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
    },
    moveUp(index) {
      this.arrayMove(this.scenario, index, index - 1)
    },
    moveDown(index) {
      this.arrayMove(this.scenario, index, index + 1)
    },
    cloneData(index) {
      this.scenario.splice(index, 0, this.scenario[index])
    },
    deleteData(index) {
      this.scenario.splice(index, 1)
    },
    async runEmulation() {
      this.countError = 0
      this.executing = true;
      this.processLog = []
      this.log("Starting scenario test...")
      let response = await axios.post(
          `https://webtest.desure.com/api`,
          {data: this.scenario}
      )
      response = response.data.split('/')
      response.forEach((item) => {
        if (item.startsWith('Error:')) {
          this.logError(item)
          this.countError++
        } else {
          this.log(item)
        }
      })
      this.log("Finishing scenario test")
      this.executing = false;
    },
    handleImportFileJson(event) {
      this.jsonFile = event.target.files[0]
      if (!this.jsonFile) {
        this.data = "No File Chosen"
        return
      }
      this.importExportProcessStarted = true
      let comp = this
      this.log('Import JSON Started')
      var reader = new FileReader()
      reader.onload = () => {
        comp.importExportProcessStarted = true
        this.scenario = JSON.parse(reader.result)
        this.log('Import JSON Finished')
        comp.importExportProcessStarted = false
      }
      reader.readAsText(this.jsonFile)
      this.jsonFile = null
      this.$refs.filePickerJson.value = null
      this.importExportProcessStarted = false

    },
    scenarioAdd() {
      let params = [...this.selectedButton.currentModel.params]
      let paramTypes = [...this.selectedButton.params.map((item) => item.type)]
      for (let i = 0; i < params.length; i++) {
        if (this.selectedButton.params[i].type == MONEY || this.selectedButton.params[i].type == PERCENT) {
          params[i] = Math.floor(params[i] * 100)
        }
        if (this.selectedButton.params[i].type == NUMBER) {
          params[i] = Math.floor(params[i])
        }
      }
      this.scenario.push({
        action: this.selectedButton.action,
        method: this.selectedButton.method,
        sender: this.selectedButton.currentModel.sender,
        params: params,
        paramTypes: paramTypes
      })
    },
    handleImportFileXls(event) {
      this.importExportProcessStarted = true
      let comp = this
      try {
        this.xlsFile = event.target.files[0]
        if (!this.xlsFile) {
          this.data = "No File Chosen"
          return
        }
        this.importExportProcessStarted = true
        const wb = new ExcelJS.Workbook()
        const reader = new FileReader()
        reader.readAsArrayBuffer(event.target.files[0])
        reader.onload = () => {
          const buffer = reader.result
          wb.xlsx.load(buffer).then(workbook => {
                workbook.eachSheet((sheet) => {
                      sheet.eachRow((row) => {
                        try {
                          let item = {}
                          item.action = row.values[1]
                          item.method = row.values[2]
                          item.sender = row.values[3]
                          item.params = []
                          item.paramTypes = []
                          for (let i = 4; i < row.values.length; i++) {
                            if (i % 2 == 0) {
                              item.paramTypes.push(row.values[i])
                            } else {
                              if (row.values[i-1] == POOLLISTID) {
                                item.params.push(JSON.parse(row.values[i]))
                              } else {
                                item.params.push(row.values[i])
                              }
                            }
                          }
                          this.scenario.push(item)
                        } catch (e) {
                          comp.importExportProcessStarted = false
                        }
                      })
                    }
                )
                this.log('Import Finished')
                comp.importExportProcessStarted = false
              }
          )
        }
      } catch (e) {
        comp.importExportProcessStarted = false
        this.logError('Problem in import Xls')
        this.logError(e)
      }

      this.$refs.filePickerXls.value = null
    },
    async importJson() {
      this.importExportProcessStarted = true
      this.processLog = []
      this.log('Import JSON Started')
      this.$refs.filePickerJson.click()
      this.importExportProcessStarted = false
    },
    async importXls() {
      this.importExportProcessStarted = true
      this.processLog = []
      this.log('Import XLS Started')
      this.$refs.filePickerXls.click()
      this.importExportProcessStarted = false
    },
    async exportXls() {
      this.importExportProcessStarted = true
      this.processLog = []
      let comp = this
      this.log('Export XLS Started')
      try {

        const workbook = new ExcelJS.Workbook()
        const sheet = workbook.addWorksheet("Export")
        for (let item of this.scenario) {
          let united = [];
          for (let index = 0; index < item.params.length; index++) {
            united.push(item.paramTypes[index])
            united.push(item.params[index])
          }
          let genitem = [item.action, item.method, item.sender, ...united]
          sheet.addRow(genitem)
        }
        workbook.xlsx.writeBuffer().then(function (buffer) {
          comp.importExportProcessStarted = true
          saveAs(new Blob([buffer], {type: 'application/octet-stream'}), 'Export_' + moment().format('YYYY_MM_DD_HH_SS') + '.xlsx')
          comp.log('Export XLS Finished')
          comp.importExportProcessStarted = false
        })
      } catch (e) {
        comp.importExportProcessStarted = false
        this.logError('Problem in exporting Xls')
        this.logError(e)
      }
      this.importExportProcessStarted = false
    },
    async saveScenarioLog(){
      this.importExportProcessStarted = true
      try {
        let blob = new Blob([this.processLog.join('\n')], {type: "text/plain;charset=utf-8"});
        saveAs(blob, 'Log_' + moment().format('YYYY_MM_DD_HH_SS') + '.txt');
      } catch (e) {
        this.importExportProcessStarted = false
        this.logError('Problem in exporting Log to txt')
        this.logError(e)
        // alert(e)
      }
      this.importExportProcessStarted = false
    },
    log(log) {
      this.processLog.push(log)
    },
    logError(log) {
      this.processLog.push(`<span style="color:red">${log}</span>`)
    }
  },
}

</script>

<style lang="scss">

.main-container {
  display: grid;
  grid-template-columns: 66fr 34fr;
  grid-template-rows: auto 34fr;
  height: 100%;
}

.log-card {
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: 2;
  grid-column-end: 3;
  display: grid !important;
  grid-template-rows: auto 1fr auto;
}

.log-container {
  background-color: #eeeeee;
  padding: 1rem;
  overflow-y: scroll;
}


.scenarion-container {
  background-color: aquamarine;
  margin-top: 1rem;
  padding: 1rem;
}

.header {
  text-align: center;
}

.left-buttons-block {
  border-right: 1px solid lightgray;

  .v-btn {
    text-transform: none !important;
  }

  .v-btn-toggle {
    flex-direction: column !important;
    width: 100%;
  }
}

.scenario-card {
  display: grid !important;
  grid-template-rows: auto 1fr;
}
</style>
