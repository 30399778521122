export const Users = []
export const Senders = []
export const Pools = []
export const Claims = []
export const Risks = []

export const USERID = 'userId'
export const POOLID = 'poolId'
export const POOLLISTID = 'poolListId'
export const CLAIMID = 'claimId'
export const RISKFACTORID = 'riskFactorId'
export const NUMBER = 'number'
export const PERCENT = 'percent'
export const BOOL = 'bool'
export const MONEY = 'money'
export const STRING = 'string'

for (let i = 0; i < 500; i++) {
    Users.push({text: 'User ' + (i + 1), value: i})
}

for (let i = 0; i < 500; i++) {
    Senders.push({text: 'User ' + (i + 1), value: i})
}

for (let i = 0; i < 50; i++) {
    Pools.push({text: 'Pool ' + (i + 1), value: i})
}

for (let i = 0; i < 500; i++) {
    Claims.push({text: 'Claim ' + (i + 1), value: i})
}

for (let i = 0; i <= 50; i++) {
    Risks.push({text: 'RiskFactor ' + (i + 1), value: i})
}

export const Controls = [
    {
        'tab': {caption: "Bank", color: "lime"},
        selectedButton: undefined,
        'btns': [
            {
                caption: "recordLoadedFromReinsuranceDollars",
                action: "desureBankContract",
                method: "recordLoadedFromReinsuranceDollars",
                sender: "ClaimsManager",
                param1: {type: MONEY, value: 0, caption: "How much money received from reinsurance, $"},
            },
            {
                caption: "withdrawLeftovers",
                action: "desureBankContract",
                method: "withdrawLeftovers",
                sender: 0,
                param1: {type: MONEY, value: 0, caption: "How much money to withdraw, $"},
                param2: {type: BOOL, value: false, caption: "Recalculate Leftovers first"}
            },
            {
                caption: "withdrawLeftoversForPersonWithConfirmedClaim",
                action: "desureBankContract",
                method: "withdrawLeftoversForPersonWithConfirmedClaim",
                sender: "Not Important",
                param1: {type: USERID, value: 0, caption: "For which user"},
                param2: {type: MONEY, value: 0, caption: "How much money to withdraw, $"}
            },
            // Getters
            {
                caption: "getJoinDollars",
                action: "desureBankContract",
                method: "getJoinDollars",
                sender: "Not Important",
            },
            {
                caption: "getFeesDollars",
                action: "desureBankContract",
                method: "getFeesDollars",
                sender: "Not Important",
            },
            {
                caption: "getServiceFeesDollars",
                action: "desureBankContract",
                method: "getServiceFeesDollars",
                sender: "Not Important",
            },
            {
                caption: "getLoadedFromReinsuranceDollars",
                action: "desureBankContract",
                method: "getLoadedFromReinsuranceDollars",
                sender: "Not Important",
            },
            {
                caption: "getSpendReinsuranceDollars",
                action: "desureBankContract",
                method: "getSpendReinsuranceDollars",
                sender: "Not Important",
            },
        ]
    },
    {
        'tab': {caption: "Bank 2", color: "lime"},
        selectedButton: undefined,
        'btns': [
            // Fines
            {
                caption: "getFineDollars",
                action: "desureBankContract",
                method: "getFineDollars",
                sender: "Not Important",
            },
            // Claims
            {
                caption: "getPreliminaryClaimDollars",
                action: "desureBankContract",
                method: "getPreliminaryClaimDollars",
                sender: "Not Important",
            },
            {
                caption: "getPreliminaryTreasuryDollars",
                action: "desureBankContract",
                method: "getPreliminaryTreasuryDollars",
                sender: "Not Important",
            },
            {
                caption: "getConfirmedClaimDollars",
                action: "desureBankContract",
                method: "getConfirmedClaimDollars",
                sender: "Not Important",
            },
            {
                caption: "getConfirmedTreasuryDollars",
                action: "desureBankContract",
                method: "getConfirmedTreasuryDollars",
                sender: "Not Important",
            },
            {
                caption: "getPaidClaimDollars",
                action: "desureBankContract",
                method: "getPaidClaimDollars",
                sender: "Not Important",
            },
            // Leftovers
            {
                caption: "getCalculatedLeftoversDollars",
                action: "desureBankContract",
                method: "getCalculatedLeftoversDollars",
                sender: "Not Important",
            },
            {
                caption: "getConsumedLeftoversDollars",
                action: "desureBankContract",
                method: "getConsumedLeftoversDollars",
                sender: "Not Important",
            },
            {
                caption: "getWithdrawnLeftoversDollars",
                action: "desureBankContract",
                method: "getWithdrawnLeftoversDollars",
                sender: "Not Important",
            },
        ]
    },
    {
        'tab': {caption: "Claims", color: "lime"},
        selectedButton: undefined,
        'btns': [
            {
                caption: "makeClaim",
                action: "desureClaimsContract",
                method: "makeClaim",
                sender: 0,
                param1: {type: USERID, value: 0, caption: "For User"},
                param2: {type: NUMBER, value: 0, caption: "Month of Death"}
            },
            {
                caption: "confirmClaim",
                action: "desureClaimsContract",
                method: "confirmClaim",
                sender: "ClaimsManager",
                param1: {type: CLAIMID, value: 1, caption: "Claim ID"}
            },
            {
                caption: "setReinsurancePayment",
                action: "desureClaimsContract",
                method: "setReinsurancePayment",
                sender: "ClaimsManager",
                param1: {type: CLAIMID, value: 1, caption: "Claim ID"},
                param2: {type: MONEY, value: 0, caption: "New payments from reinsurance"}
            },
            {
                caption: "rejectClaim",
                action: "desureClaimsContract",
                method: "rejectClaim",
                sender: "ClaimsManager",
                param1: {type: CLAIMID, value: 1, caption: "Claim ID"}
            },
            {
                caption: "payClaim",
                action: "desureClaimsContract",
                method: "payClaim",
                sender: "ClaimsManager",
                param1: {type: CLAIMID, value: 1, caption: "Claim ID"}
            },
            {
                caption: "getClaimById",
                action: "desureClaimsContract",
                method: "getClaimById",
                sender: "Not Important",
                param1: {type: CLAIMID, value: 1, caption: "Claim ID"}
            },
            {
                caption: "getClaimsCount",
                action: "desureClaimsContract",
                method: "getClaimsCount",
                sender: "Not Important",
            },
        ]
    },
    {
        'tab': {caption: "Court", color: "lime"},
        selectedButton: undefined,
        'btns': [
            {
                caption: "approveClaim",
                action: "desureCourtContract",
                method: "approveClaim",
                sender: "CourtManager",
                param1: {type: CLAIMID, value: 1, caption: "Claim ID"},
            },
            {
                caption: "rejectClaim",
                action: "desureCourtContract",
                method: "rejectClaim",
                sender: "CourtManager",
                param1: {type: CLAIMID, value: 1, caption: "Claim ID"},
            },
            {
                caption: "approveUserChangeRequest",
                action: "desureCourtContract",
                method: "approveUserChangeRequest",
                sender: "CourtManager",
                param1: {type: USERID, value: 0, caption: "User to migrate"},
                param2: {type: USERID, value: 0, caption: "To new user"},
            },
            {
                caption: "approveBeneficiaryChangeRequest",
                action: "desureCourtContract",
                method: "approveBeneficiaryChangeRequest",
                sender: "CourtManager",
                param1: {type: USERID, value: 0, caption: "User to change beneficiary"},
                param2: {type: USERID, value: 0, caption: "New Beneficiary"},
            },
            {
                caption: "approveUserInfoChangeRequest",
                action: "desureCourtContract",
                method: "approveUserInfoChangeRequest",
                sender: "CourtManager",
                param1: {type: USERID, value: 0, caption: "User to change data"},
                param2: {type: STRING, value: 0, caption: "New User Data"},
            },
        ]
    },

    {
        'tab': {caption: "Membership", color: "lime"},
        selectedButton: undefined,
        'btns': [
            {
                caption: "join",
                action: "desureMembershipContract",
                method: "join",
                sender: 0,
                param1: {type: USERID, value: 0, caption: "Beneficiary"},
                param2: {type: STRING, value: 'Enter Value', caption: "User Info"},
                param3: {type: POOLLISTID, value: [], caption: "Pools to Join"},
                param4: {type: RISKFACTORID, value: 0, caption: "RiskFactor ID"},
                param5: {type: BOOL, value: false, caption: "Use Leftovers"},
                param6: {type: NUMBER, value: 1, caption: "For How Many Years"},
            },
            {
                caption: "leave",
                action: "desureMembershipContract",
                method: "leave",
                param1: {type: POOLLISTID, value: [], caption: "Pools to Leave"},
                sender: 0,
            },
            {
                caption: "getClientByAddress",
                action: "desureMembershipClientsContract",
                method: "getClientByAddress",
                sender: "Not Important",
                param1: {type: USERID, value: 0, caption: "Which User"},
            },
            {
                caption: "isActiveClient",
                action: "desureMembershipClientsContract",
                method: "isActiveClient",
                sender: "Not Important",
                param1: {type: USERID, value: 0, caption: "Which User"},
            },
            {
                caption: "getUserBalance",
                action: "desureMembershipClientsPoolsContract",
                method: "getUserBalance",
                sender: "Not Important",
                param1: {type: USERID, value: 0, caption: "Which User"},
                param2: {type: NUMBER, value: 1, caption: "For which month (use current here)"},
            },
            {
                caption: "updateCalculatedLeftovers",
                action: "desureMembershipClientsPoolsContract",
                method: "updateCalculatedLeftovers",
                sender: "Not Important",
                param1: {type: USERID, value: 0, caption: "For which User"},
            },
            {
                caption: "getClientJoinedPoolsById",
                action: "desureMembershipClientsPoolsContract",
                method: "getClientJoinedPoolsById",
                sender: "Not Important",
                param1: {type: USERID, value: 0, caption: "For which User"},
                param2: {type: POOLID, value: 1, caption: "For which Pool"},
            },
            {
                caption: "isUserInsuredAt",
                action: "desureMembershipClientsPoolsContract",
                method: "isUserInsuredAt",
                sender: "Not Important",
                param1: {type: USERID, value: 0, caption: "Which User"},
                param2: {type: POOLID, value: 1, caption: "In which Pool"},
                param3: {type: NUMBER, value: 0, caption: "At which month"}
            },
        ]
    },
    {
        'tab': {caption: "Management", color: "lime"},
        selectedButton: undefined,
        'btns': [
            {
                caption: "setFees",
                action: "desureMembershipManagementContract",
                method: "setFees",
                sender: "MemebershipManager",
                param1: {type: MONEY, value: 100, caption: "User Migration Fee"},
                param2: {type: MONEY, value: 100, caption: "User Beneficiary change Fee"},
                param3: {type: MONEY, value: 100, caption: "User Info change Fee"}
            },
            {
                caption: "requestUserAddressChange",
                action: "desureMembershipManagementContract",
                method: "requestUserAddressChange",
                sender: 0,
                param1: {type: USERID, value: 0, caption: "For User"},
                param2: {type: USERID, value: 0, caption: "New User"},
            },
            {
                caption: "requestBeneficiaryChange",
                action: "desureMembershipManagementContract",
                method: "requestBeneficiaryChange",
                sender: 0,
                param1: {type: USERID, value: 0, caption: "For User"},
                param2: {type: USERID, value: 0, caption: "New Beneficiary"},
            },
            {
                caption: "requestUserInfoChange",
                action: "desureMembershipManagementContract",
                method: "requestUserInfoChange",
                sender: 0,
                param1: {type: USERID, value: 0, caption: "For User"},
                param2: {type: STRING, value: '', caption: "New User Info"},
            },
            {
                caption: "approveUserChangeRequest",
                action: "desureMembershipManagementContract",
                method: "approveUserChangeRequest",
                sender: "MemebershipManager",
                param1: {type: USERID, value: 0, caption: "For User"},
                param2: {type: USERID, value: 0, caption: "New User"},
            },
            {
                caption: "approveBeneficiaryChangeRequest",
                action: "desureMembershipManagementContract",
                method: "approveBeneficiaryChangeRequest",
                sender: "MemebershipManager",
                param1: {type: USERID, value: 0, caption: "For User"},
                param2: {type: USERID, value: 0, caption: "New Beneficiary"},
            },
            {
                caption: "approveUserInfoChangeRequest",
                action: "desureMembershipManagementContract",
                method: "approveUserInfoChangeRequest",
                sender: "MemebershipManager",
                param1: {type: USERID, value: 0, caption: "For User"},
                param2: {type: STRING, value: '', caption: "New User Info"},
            },
            {
                caption: "getFees",
                action: "desureMembershipManagementContract",
                method: "getFees",
                sender: "Not Important",
            },
            {
                caption: "getUserChangeRequests",
                action: "desureMembershipManagementContract",
                method: "getUserChangeRequests",
                sender: "Not Important",
                param1: {type: USERID, value: 0, caption: "For which User"},
            },
            {
                caption: "getBeneficiaryChangeRequests",
                action: "desureMembershipManagementContract",
                method: "getBeneficiaryChangeRequests",
                sender: "Not Important",
                param1: {type: USERID, value: 0, caption: "For which User"},
            },
            {
                caption: "getUserInfoChangeRequest",
                action: "desureMembershipManagementContract",
                method: "getUserInfoChangeRequest",
                sender: "Not Important",
                param1: {type: USERID, value: 0, caption: "For which User"},
            },
        ]
    },

    {
        'tab': {caption: "Pools", color: "lime"},
        selectedButton: undefined,
        'btns': [
            {
                caption: "addPool",
                action: "desurePoolsContract",
                method: "addPool",
                sender: "PoolsManager",
                param1: {type: MONEY, value: 0, caption: "Join Sum, yearly, $"},
                param2: {type: MONEY, value: 0, caption: "Max pay per claim, $"},
                param3: {type: MONEY, value: 0, caption: "Max Payout, $"},
                param4: {type: MONEY, value: 0, caption: "Fee, monthly, $"},
                param5: {type: PERCENT, value: 0, caption: "Fine for early exit, %"},
                param6: {type: PERCENT, value: 0, caption: "Claim fee - % of claim to treasury"},
                param7: {type: BOOL, value: 0, caption: "Should be activated?"},
            },
            {
                caption: "disablePool",
                action: "desurePoolsContract",
                method: "disablePool",
                sender: "PoolsManager",
                param1: {type: POOLID, value: 1, caption: "Pool ID"},
            },
            {
                caption: "enablePool",
                action: "desurePoolsContract",
                method: "enablePool",
                sender: "PoolsManager",
                param1: {type: POOLID, value: 1, caption: "Pool ID"},
            },
            {
                caption: "updatePool",
                action: "desurePoolsContract",
                method: "updatePool",
                sender: "PoolsManager",
                param1: {type: POOLID, value: 1, caption: "Pool ID"},
                param2: {type: MONEY, value: 0, caption: "Join Sum, yearly, $"},
                param3: {type: MONEY, value: 0, caption: "Max pay per claim, $"},
                param4: {type: MONEY, value: 0, caption: "Max Payout, $"},
                param5: {type: MONEY, value: 0, caption: "Fee, monthly, $"},
                param6: {type: PERCENT, value: 0, caption: "Fine for early exit, %"},
                param7: {type: PERCENT, value: 0, caption: "Claim fee - % of claim to treasury"},
            },
            {
                caption: "getPoolById",
                action: "desurePoolsContract",
                method: "getPoolById",
                sender: "Not Important",
                param1: {type: POOLID, value: 1, caption: "Pool ID"},
            },
            {
                caption: "getActivePoolsCount",
                action: "desurePoolsContract",
                method: "getActivePoolsCount",
                sender: "Not Important",
            },
            {
                caption: "getTotalPoolsCount",
                action: "desurePoolsContract",
                method: "getTotalPoolsCount",
                sender: "Not Important",
            },
        ]
    },
    {
        'tab': {caption: "RiskFactors", color: "lime"},
        selectedButton: undefined,
        'btns': [
            {
                caption: "addRiskFactor",
                action: "desureRiskFactorsContract",
                method: "addRiskFactor",
                sender: "RiskFactorsManager",
                param1: {type: PERCENT, value: 0, caption: "Risk Factor Multiplier"},
                param2: {type: BOOL, value: 0, caption: "Should be activated?"},
            },
            {
                caption: "disableRiskFactor",
                action: "desureRiskFactorsContract",
                method: "disableRiskFactor",
                sender: "RiskFactorsManager",
                param1: {type: RISKFACTORID, value: 0, caption: "Risk Factor ID"},
            },
            {
                caption: "enableRiskFactor",
                action: "desureRiskFactorsContract",
                method: "enableRiskFactor",
                sender: "RiskFactorsManager",
                param1: {type: RISKFACTORID, value: 0, caption: "Risk Factor ID"},
            },
            {
                caption: "updateRiskFactorMultiplier",
                action: "desureRiskFactorsContract",
                method: "updateRiskFactorMultiplier",
                sender: "RiskFactorsManager",
                param1: {type: RISKFACTORID, value: 0, caption: "Risk Factor ID"},
                param2: {type: PERCENT, value: 0, caption: "Should be activated?"},
            },
            {
                caption: "getRiskFactorById",
                action: "desureRiskFactorsContract",
                method: "getRiskFactorById",
                sender: "Not Important",
                param1: {type: RISKFACTORID, value: 0, caption: "Risk Factor ID"},
            },
            {
                caption: "getRiskFactorCount",
                action: "desureRiskFactorsContract",
                method: "getRiskFactorCount",
                sender: "Not Important",
            },
            {
                caption: "getActiveRiskFactorsCount",
                action: "desureRiskFactorsContract",
                method: "getActiveRiskFactorsCount",
                sender: "Not Important",
            },

        ]
    },
    {
        'tab': {caption: "Token & Users", color: "orange"},
        selectedButton: undefined,
        'btns': [
            {
                caption: "mintFor",
                action: "desureUSDTContract",
                method: "mintFor",
                sender: "Admin",
                param1: {type: USERID, value: 0, caption: "For User"},
                param2: {type: MONEY, value: 0, caption: "How Much, $"}
            },
            {
                caption: "mintForBank",
                action: "desureUSDTContract",
                method: "mintForBank",
                sender: "Admin",
                param1: {type: MONEY, value: 0, caption: "How Much, $"}
            },
            {
                caption: "getBankBalance",
                action: "desureUSDTContract",
                method: "getBankBalance",
                sender: "Not Important"
            },
            {
                caption: "approve",
                action: "desureUSDTContract",
                method: "approve",
                sender: 0,
                param1: {type: MONEY, value: 0, caption: "How Much, $"}
            },
            {
                caption: "transfer",
                action: "desureUSDTContract",
                method: "transfer",
                sender: 0,
                param1: {type: USERID, value: 0, caption: "To Whom"},
                param2: {type: MONEY, value: 0, caption: "How Much, $"}
            },
            {
                caption: "balanceOf",
                action: "desureUSDTContract",
                method: "balanceOf",
                sender: "Not Important",
                param1: {type: USERID, value: 0, caption: "Whom Balance"},
            },
            {
                caption: "printUserAddress",
                action: "special",
                method: "printUserAddress",
                sender: "Not Important",
                param1: {type: USERID, value: 0, caption: "Which User"}
            },

        ]
    },
    {
        'tab': {caption: "Time", color: "orange"},
        selectedButton: undefined,
        'btns': [
            {
                caption: "Advance 1 month",
                action: "special",
                method: "advanceMonth",
                sender: "Not Important",
            },
            {
                caption: "Advance 1 year",
                action: "special",
                method: "advanceYear",
                sender: "Not Important",
            },
            {
                caption: "getCurrentMonthYear",
                action: "desureDateTimeUtilsContract",
                method: "getCurrentMonthYear",
                sender: "Not Important",
            },
        ]
    }
]
